import * as Sentry from '@sentry/angular-ivy';
import { FirebaseOptions } from 'firebase/app';

export interface EnvironmentType {
  production: boolean;
  domain: string;
  kaisatsuApiBaseUrl: string;
  recaptchaSitekey: string;
  platformReferrerUrl: string;
  settingReferrerUrl: string;
  partnerReferrerUrl: string;
  studentAppsUrl: string;
  movieAppsUrl: string;
  teacherAppsUrl: string;
  homeAppsUrls: string;
  classiCookieDomain: string;
  benesseLoginUrl: {
    teacher: string;
    student: string;
    parent: string;
  };
  sentry: Sentry.BrowserOptions | null;
  firebase: FirebaseOptions;
}

export const baseEnvironment = {
  studentAppsUrl: 'every://login/success',
  movieAppsUrl: 'flip://login/success',
  teacherAppsUrl: 'pocketbook://login/success',
  homeAppsUrls: 'portal://login/success',
};
