import {
  HttpClient,
  HttpParams,
  HttpParamsOptions,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  GetSsoStudentsCsvParams,
  GetSsoTeachersCsvParams,
} from '../../pages/school-sso-users-setting/download-form/download-form-sso-users.component';
import { handleErrorResponse } from './response.error';
import {
  SchoolLoginOptions,
  SsoSearchOptions,
  SsoUsersList,
  SsoUsersSearchParams,
} from './types';
const baseUrl = `${environment.kaisatsuApiBaseUrl}/api`;

@Injectable({
  providedIn: 'root',
})
export class SchoolAdminService {
  constructor(private readonly http: HttpClient) {}

  /**
   * @see https://kaisatsu.stg.classi.jp/docs/api/index.html
   */

  async getLoginOptions(): Promise<SchoolLoginOptions> {
    const response = await this.http
      .get<{
        success: true;
        data: SchoolLoginOptions;
      }>(`${baseUrl}/v1/school/admin/login_options`)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async putLoginOptions(
    options: SchoolLoginOptions
  ): Promise<SchoolLoginOptions> {
    const response = await this.http
      .put<{
        success: true;
        data: SchoolLoginOptions;
      }>(`${baseUrl}/v1/school/admin/login_options`, options)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async getSearchOptions(school_year?: {
    school_year: number;
  }): Promise<SsoSearchOptions> {
    const paramsOptions = <HttpParamsOptions>{ fromObject: school_year };
    const params = new HttpParams(paramsOptions);
    const response = await this.http
      .get<{
        success: true;
        data: SchoolLoginOptions;
      }>(`${baseUrl}/v1/school/admin/search_options?`, { params })
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async getSsoTeachersList(
    searchParams: SsoUsersSearchParams
  ): Promise<SsoUsersList> {
    const response = await this.http
      .get<{
        success: true;
        data: SsoUsersList;
      }>(`${baseUrl}/v1/school/admin/sso/teachers`, {
        params: this.createHttpParams(searchParams),
      })
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async getSsoStudentsList(
    searchParams: SsoUsersSearchParams
  ): Promise<SsoUsersList> {
    const response = await this.http
      .get<{
        success: true;
        data: SsoUsersList;
      }>(`${baseUrl}/v1/school/admin/sso/students`, {
        params: this.createHttpParams(searchParams),
      })
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  getSsoTeachersCsv(searchParams: GetSsoTeachersCsvParams): void {
    window.open(
      `${baseUrl}/v1/school/admin/sso/teachers.csv?${this.createHttpParams(
        searchParams
      ).toString()}`
    );
  }

  getSsoStudentsCsv(searchParams: GetSsoStudentsCsvParams): void {
    window.open(
      `${baseUrl}/v1/school/admin/sso/students.csv?${this.createHttpParams(
        searchParams
      ).toString()}`
    );
  }

  async postSsoTeachersCsv(formData: FormData): Promise<void> {
    await this.http
      .post<{
        success: true;
      }>(`${baseUrl}/v1/school/admin/sso/teachers`, formData)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async postSsoStudentsCsv(formData: FormData): Promise<void> {
    await this.http
      .post<{
        success: true;
      }>(`${baseUrl}/v1/school/admin/sso/students`, formData)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async deleteSsoUserGoogleAccount(id: string): Promise<void> {
    await this.http
      .delete<{
        success: true;
      }>(`${baseUrl}/v1/school/admin/sso/users/${id}/google`)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async deleteSsoUserMicrosoftAccount(id: string): Promise<void> {
    await this.http
      .delete<{
        success: true;
      }>(`${baseUrl}/v1/school/admin/sso/users/${id}/microsoft`)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async postSsoUserGoogleAccount(
    id: string,
    params: { google_account: string }
  ): Promise<void> {
    await this.http
      .post<{
        success: true;
      }>(`${baseUrl}/v1/school/admin/sso/users/${id}/google`, params)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async postSsoUserMicrosoftAccount(
    id: string,
    params: { microsoft_account: string }
  ): Promise<void> {
    await this.http
      .post<{
        success: true;
      }>(`${baseUrl}/v1/school/admin/sso/users/${id}/microsoft`, params)
      .toPromise()
      .catch(handleErrorResponse);
  }

  private createHttpParams(params: { [key: string]: any }): HttpParams {
    // valueがnullやundefinedなものは弾く
    for (let key in params) {
      if (!params[key]) {
        delete params[key];
      }
    }
    return new HttpParams({ fromObject: params });
  }
}
