import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../shared/api/login.service';
import { KaisatsuResponseError } from '../../shared/api/response.error';
import { GlobalMessageService } from '../../shared/global-message.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';

@Component({
  selector: 'app-bc-login-manabi-complete',
  templateUrl: './bc-login-manabi-complete.component.html',
  styleUrls: ['./bc-login-manabi-complete.component.scss'],
})
export class BcLoginManabiCompleteComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly loginService: LoginService,
    private readonly globalMessageService: GlobalMessageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle(this.route.snapshot.data.title);

    try {
      // Classi Cookieを発行してもらう
      await this.loginService.issueCookie();
      // platformへリダイレクト
      window.location.href = environment.platformReferrerUrl;
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
      this.router.navigateByUrl('/bc_login/manabi/begin');
    }
  }
}
