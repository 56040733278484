import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PasswordLoginChallengeParams } from '../../pages/login-challenge/password/password-login-challenge.component';
import {
  ChooseAccountResponse,
  LoginMethod,
  LoginResponse,
  User,
} from './types';
import { environment } from '../../../environments/environment';
import { handleErrorResponse } from './response.error';
import { TosResponse } from '../../pages/login-tos-agreement/login-tos-agreement.component';
import { LoginInitialSetting } from '../../pages/login-initial-setting/login-initial-setting.component';
import { LoginContinueResponse } from '../../pages/login-continue/login-continue.component';
import { LoginProfile } from '../../pages/login-profile-confirmation/login-profile-confirmation.component';

const baseUrl = `${environment.kaisatsuApiBaseUrl}/api`;
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  /**
   * @see https://kaisatsu.stg.classi.jp/docs/api/index.html
   */

  async getLoginMethods(
    username: string,
    opt?: { manabi: boolean }
  ): Promise<LoginMethod[]> {
    // パラメーターをURLに載せたくないのでPOSTでデータを取得する
    const data = opt && opt.manabi ? { username, manabi: true } : { username };
    const response = await this.http
      .post<{
        success: true;
        data: LoginMethod[];
      }>(`${baseUrl}/v1/login_methods`, data)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async postLoginWithPassword(
    username: string,
    params: PasswordLoginChallengeParams
  ): Promise<LoginResponse> {
    const response = await this.http
      .post<{ success: true; data: LoginResponse }>(
        `${baseUrl}/v1/login/with_password`,
        {
          username: username,
          ...params,
        }
      )
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async postLoginWithGoogle(id_token: string): Promise<void> {
    const response = await this.http
      .post<{}>(`${baseUrl}/v1/login/with_google`, {
        id_token,
      })
      .toPromise()
      .catch(handleErrorResponse);
  }

  async postLoginWithMicrosoft(id_token: string): Promise<void> {
    // TODO: URLは後で変わる
    await this.http
      .post<{}>(`${baseUrl}/v1/login/with_microsoft`, {
        id_token,
      })
      .toPromise()
      .catch(handleErrorResponse);
  }

  async PostTosAgreement(): Promise<{ password_change_required: boolean }> {
    const response = await this.http
      .post<{
        success: true;
        password_change_required: boolean;
      }>(`${baseUrl}/v1/login/tos_agreement`, null)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async postLoginInitialSetting(params: LoginInitialSetting): Promise<void> {
    await this.http
      .post<{ success: true }>(`${baseUrl}/v1/login/initial_setting`, params)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async getTos(): Promise<TosResponse> {
    const response = await this.http
      .get<TosResponse>(`${baseUrl}/v1/tos`)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async getChooseAccount(): Promise<ChooseAccountResponse> {
    const response = await this.http
      .get<{
        success: true;
        data: ChooseAccountResponse;
      }>(`${baseUrl}/v1/login/choose_account`)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async postChooseAccount(params: {
    username: string;
    set_default: boolean;
  }): Promise<void> {
    await this.http
      .post<{ success: true }>(`${baseUrl}/v1/login/choose_account`, params)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async getLoginContinue(): Promise<LoginContinueResponse> {
    const response = await this.http
      .get<{
        success: true;
        data: LoginContinueResponse;
      }>(`${baseUrl}/v1/login/continue`)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async getLoginProfile(): Promise<LoginProfile> {
    const response = await this.http
      .get<{
        success: true;
        data: LoginProfile;
      }>(`${baseUrl}/v1/login/profile_confirmation`)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async postProfileConfirmation(): Promise<void> {
    await this.http
      .post<{ success: true }>(`${baseUrl}/v1/login/profile_confirmation`, null)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async issueCookie(): Promise<void> {
    await this.http
      .post<{ success: true }>(`${baseUrl}/v1/login/issue_cookie`, null)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async issueAccessToken(): Promise<{ token: string }> {
    const response = await this.http
      .post<{
        success: true;
        data: { token: string };
      }>(`${baseUrl}/v1/login/issue_access_token`, null)
      .toPromise()
      .catch(handleErrorResponse);

    return response.data;
  }

  async postInvitationCode(params: {
    benesse_invitation_code: string;
  }): Promise<void> {
    await this.http
      .post<{ success: true }>(
        `${baseUrl}/v1/login/consume_benesse_invitation_code`,
        {
          benesse_invitation_code: params.benesse_invitation_code,
        }
      )
      .toPromise()
      .catch(handleErrorResponse);
  }

  async deleteSession(): Promise<void> {
    await this.http
      .delete<{ success: true }>(`${baseUrl}/v1/logout`)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async postEmailRegistrationGuide(): Promise<void> {
    await this.http
      .post<{
        success: true;
      }>(`${baseUrl}/v1/login/email_registration_guide`, null)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async getEmailConfirmationCode(params: { email: string }): Promise<void> {
    await this.http
      .post<{
        success: true;
      }>(`${baseUrl}/v1/login/email_registration_code`, params)
      .toPromise()
      .catch(handleErrorResponse);
  }

  async postEmailAndConfirmationCode(params: {
    email: string;
    confirmation_code: string;
  }): Promise<void> {
    await this.http
      .post<{
        success: true;
      }>(`${baseUrl}/v1/login/email_registration`, params)
      .toPromise()
      .catch(handleErrorResponse);
  }
}
