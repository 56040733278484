<ng-container *ngIf="renderPage">
  <app-header [logoHeader]="true" serviceName="ログイン画面"></app-header>

  <main>
    <app-global-message></app-global-message>

    <section class="login-box">
      <div class="login classi">
        <app-bc-login-manabi-begin-form
          [submitInProgress]="submitInProgress"
          [formErrors]="formErrors"
          (login)="getLogin($event)"
        ></app-bc-login-manabi-begin-form>
      </div>
    </section>
  </main>

  <app-footer></app-footer>
</ng-container>
