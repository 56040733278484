// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import env from '../../env.json';
import { baseEnvironment, EnvironmentType } from './environment.base';

export const environment: EnvironmentType = {
  ...baseEnvironment,
  production: true,
  domain: 'https://id.stg.classi.jp',
  kaisatsuApiBaseUrl: 'https://kaisatsu.stg.classi.jp',
  recaptchaSitekey: env.recaptcha_site_key,
  platformReferrerUrl: 'https://platform.stg.classi.jp',
  settingReferrerUrl: 'https://setting.stg.classi.jp',
  partnerReferrerUrl: 'https://partner.stg.classi.jp',
  benesseLoginUrl: {
    teacher: 'https://sas-t-az.benesse.ne.jp/classi/t/',
    student: 'https://sas-t-az.benesse.ne.jp/classi/s/',
    parent: 'https://sas-t-az.benesse.ne.jp/classi/p/',
  },
  classiCookieDomain: 'stg.classi.jp',
  sentry: {
    dsn: 'https://4b21a542f7f84e4693560329d955ce3b@o53397.ingest.sentry.io/5986400',
    environment: 'staging',
    release: env.release,
  },
  firebase: {
    apiKey: env.firebase_api_key,
    authDomain: 'id-sso.stg.classi.jp',
    projectId: 'classi-service-stg',
    storageBucket: 'classi-service-stg.appspot.com',
    messagingSenderId: '1083056860621',
    appId: '1:1083056860621:web:e7df947d07b14a571ce884',
    measurementId: 'G-461QF3KXTN',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
