import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SpenSelectOption, SpenSelectValue } from '@classi/spen';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SchoolAdminService } from '../../../shared/api/school-admin.service';
import {
  Classroom,
  Grade,
  SchoolStage,
  SearchSchoolYear,
} from '../../../shared/api/types';
import { kaisatsuHandleError } from '../../../shared/kaisatsu-error-handler';

export type GetSsoTeachersCsvParams = {
  school_year: number;
  stage_id?: number;
  grade_id?: number;
};

export type GetSsoStudentsCsvParams = {
  school_year: number;
  stage_id?: number;
  grade_id?: number;
  classroom_id?: number;
};

@Component({
  selector: 'app-download-form-manabi',
  templateUrl: './download-form-manabi.component.html',
  styleUrls: ['./download-form-manabi.component.scss'],
})
export class DownloadFormManabiComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input()
  schoolYears: SearchSchoolYear[] = [];
  @Input()
  schoolStages: SchoolStage[] = [];
  @Input()
  grades: Grade[] = [];
  @Input()
  classrooms: Classroom[] = [];

  schoolYearOptions: SpenSelectOption[] = [];
  schoolStageOptions: SpenSelectOption[] = [];
  gradeOptions: SpenSelectOption[] = [];
  classroomOptions: SpenSelectOption[] = [];
  downloadInProgress = false;

  readonly form = this.fb.group({
    account_type: this.fb.control(null, {
      validators: [Validators.required],
    }),
    school_year: this.fb.control(null, { validators: [Validators.required] }),
    stage_id: this.fb.control(null, {}),
    grade_id: this.fb.control(null, {}),
    classroom_id: this.fb.control(null, {}),
  });

  onDestroy$ = new Subject();

  @Output()
  changeSchoolYear = new EventEmitter<SpenSelectValue>();

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly schoolAdminService: SchoolAdminService
  ) {}

  ngOnInit(): void {
    this.buildSelectBoxOptions();
    this.setSelectedSchoolYear();
    // 初期状態は先生にチェックを入れておく
    this.form.controls.account_type.setValue('teacher');

    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.buildSelectBoxOptions();
    });
  }

  ngOnChanges(): void {
    const accountTypeValue = this.form.controls.account_type.value;
    // account_typeはそのままでformをリセットする
    this.form.reset({ account_type: accountTypeValue });
    this.buildSelectBoxOptions();
    this.setSelectedSchoolYear();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  get accountTypeValue(): any {
    return this.form.controls.account_type.value;
  }
  get isGradeEnabled(): boolean {
    return this.gradeOptions.length > 0;
  }

  get isClassroomEnabled(): boolean {
    return this.classroomOptions.length > 0;
  }

  selectStage() {
    this.form.controls.grade_id.setValue(null);
    this.form.controls.classroom_id.setValue(null);
  }

  selectGrade() {
    this.form.controls.classroom_id.setValue(null);
  }

  async download(): Promise<void> {
    this.downloadInProgress = true;
    const accountType = this.form.value.account_type;
    try {
      if (this.accountTypeValue === 'teacher') {
        // 先生の場合、classroom_idは送りたくないので暫定対応としてreset()
        this.form.controls.classroom_id.reset();
        this.schoolAdminService.getSsoTeachersCsv(
          this.form.value as GetSsoTeachersCsvParams
        );
      }
      if (this.accountTypeValue === 'student') {
        this.schoolAdminService.getSsoStudentsCsv(
          this.form.value as GetSsoStudentsCsvParams
        );
      }
    } catch (e) {
      kaisatsuHandleError(e);
    }
    this.downloadInProgress = false;
  }

  private setSelectedSchoolYear(): void {
    const selectedSchoolYear = this.schoolYears.find(
      (school_year) => school_year.selected
    );
    if (selectedSchoolYear) {
      this.form.controls.school_year.setValue(selectedSchoolYear.year);
    }
  }

  private buildSelectBoxOptions() {
    this.buildSchoolYearOptions();
    this.buildSchoolStageOptions();
    this.buildGradeOptions();
    this.buildClassroomOptions();
  }

  private buildSchoolYearOptions() {
    this.schoolYearOptions = this.schoolYears.map((schoolYear) => ({
      text: `${schoolYear.year}`,
      value: schoolYear.year,
    }));
  }

  private buildSchoolStageOptions() {
    this.schoolStageOptions = this.buildFilterItemList(this.schoolStages);
  }

  private buildGradeOptions() {
    if (this.form.value.stage_id) {
      const grades = this.grades.filter(
        (grade) => grade.stage_id === this.form.value.stage_id
      );

      this.gradeOptions = this.buildFilterItemList(grades);
    } else {
      this.gradeOptions = [];
    }
  }

  private buildClassroomOptions() {
    if (this.form.value.grade_id) {
      const classrooms = this.classrooms.filter(
        (classroom) => classroom.grade_id === this.form.value.grade_id
      );
      this.classroomOptions = this.buildFilterItemList(classrooms);
    } else {
      this.classroomOptions = [];
    }
  }

  private buildFilterItemList<T extends { id: number; name: string }>(
    entities: T[]
  ): SpenSelectOption[] {
    if (entities.length === 0) {
      return [];
    }
    const items = entities.map((g) => ({ text: g.name, value: g.id }));
    return [{ text: 'すべて', value: null }, ...items];
  }
}
