import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SpenSelectValue } from '@classi/spen';
import { MyService } from '../../shared/api/my.service';
import { KaisatsuResponseError } from '../../shared/api/response.error';
import { SchoolAdminService } from '../../shared/api/school-admin.service';
import { SsoSearchOptions, User } from '../../shared/api/types';
import { GlobalMessageService } from '../../shared/global-message.service';
import { kaisatsuHandleError } from '../../shared/kaisatsu-error-handler';

@Component({
  selector: 'app-school-manabi',
  templateUrl: './school-manabi.component.html',
  styleUrls: ['./school-manabi.component.scss'],
})
export class SchoolManabiComponent implements OnInit {
  user: User | null = null;

  searchOptions: SsoSearchOptions | null = null;
  iframe = false;

  constructor(
    private readonly myService: MyService,
    private readonly schoolAdminService: SchoolAdminService,
    private readonly globalMessageService: GlobalMessageService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {}

  async ngOnInit(): Promise<void> {
    this.iframe = this.route.snapshot.queryParamMap.get('iframe') === 'true';
    this.titleService.setTitle(this.route.snapshot.data.title);

    try {
      this.user = await this.myService.getStatuses();
      this.searchOptions = await this.schoolAdminService.getSearchOptions();
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
  }

  async updateSearchOptions(schoolYear: SpenSelectValue): Promise<void> {
    const school_year = schoolYear as number;
    try {
      this.searchOptions = await this.schoolAdminService.getSearchOptions({
        school_year,
      });
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        // グローバルに表示するエラーメッセージをここで保存
        this.globalMessageService.setErrorMessage(e.messages);
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
  }
}
